<template>
  <FixedRatioContainer>
    <div class="grid-box p-8 mx-auto w-full h-full overflow-scroll no-scrollbar" style="max-width: 500px">
      <form class="flex flex-col justify-center" enctype="multipart/form-data"
            @submit.prevent="submit()">
        <ProfilePicture :font-size="100" :radius="150"></ProfilePicture>
        <div class="text-center my-2">{{ $store.getters['account/location_string'] }}</div>
        <label class="mx-auto cursor-pointer my-4" for="picture-input">
          <span class="text-disabled">{{ $t('userEdit.editPhoto') }}</span>
        </label>
        <input id="picture-input"
               class="hidden"
               type="file"
               @change="onPictureInputChange"/>

        <textarea id="profile-text-textarea" v-model="text" :placeholder="$t('userEdit.profileTextPlaceholder')"
                  class="p-4 bg-lightgray mw-600 rounded-xl border-darkgray outline-none my-10 resize-none" style="min-width: 250px"></textarea>

        <div class="flex justify-center mt-5 mb-8">
          <button :disabled="$store.getters['common/waitingForResponse']"
                  class="button-md py-4 px-4 bg-white text-blue rounded-xl border-blue mr-8"
                  @click="$router.push('/home')">
            {{ $t('userEdit.cancel') }}
          </button>
          <button :disabled="!inputHasChanged() || $store.getters['common/waitingForResponse']"
                  class="button-md py-4 px-4 bg-blue text-white rounded-xl"
                  type="submit">
            <span v-if="!$store.getters['common/waitingForResponse']">{{ $t('userEdit.save') }}</span>
            <span v-else><i class="fa fa-spinner fa-spin text-white text-xl"></i></span>
          </button>
        </div>
      </form>
    </div>
  </FixedRatioContainer>

</template>

<style>
.border-darkgray {
  border: 1px solid rgb(226, 228, 230);
}

.button-md {
  width: 150px;
}
</style>

<script>
import axios from '../axios';
import ProfilePicture from "@/components/ProfilePicture";
import FixedRatioContainer from "@/components/FixedRatioContainer";

export default {
  components: {FixedRatioContainer, ProfilePicture},
  data() {
    return {
      text: this.$store.getters['account/profile_text'] || '',
      picture: this.$store.getters['account/profile_picture']
    }
  },
  methods: {
    async onPictureInputChange() {
      this.picture = null;
      await this.submit();
    },
    inputHasChanged() {
      return this.text !== this.$store.getters['account/profile_text'] || this.picture !==
          this.$store.getters['account/profile_picture'];
    },
    async submit() {
      const picture_input = document.getElementById('picture-input');
      const data = new FormData();
      if (picture_input.files.length > 0) {
        const file = picture_input.files[0];
        if (file.size > 1024 * 1024 * 20) {
          this.$store.commit('messages/add_message', {
            message: "userEdit.pictureTooBig",
            level: "error"
          });
          picture_input.value = '';
          return;
        }

        if (['image/jpeg', 'image/png'].indexOf(file.type) === -1) {
          this.$store.commit('messages/add_message', {
            message: 'userEdit.unsupportedPictureFormat',
            level: 'error'
          });
          picture_input.value = '';
          return;
        }
        data.append("profile_picture", picture_input.files[0]);
      }

      if(this.text.length > 0) {
        data.append("profile_text", this.text);
      }

      const response = await axios.post('account/update/', data);
      this.$store.commit('account/update_profile', {
        profile_text: this.text,
        profile_picture: response.data.profile_picture
      });
      picture_input.value = '';
      this.$store.commit('messages/add_message', {
        level: 'success',
        message: 'userEdit.success'
      });
      await this.$router.push('/user-edit');
    }
  }
}
</script>